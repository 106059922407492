import { AxiosResponse, AxiosResponseHeaders } from 'axios';

export function response<T>(
  data: T,
  status = 200,
  headers: AxiosResponseHeaders = { 'x-csrf-token': 'mock-csrf-token' },
): AxiosResponse<T> {
  return {
    data: JSON.parse(JSON.stringify(data)),
    status,
    statusText: status.toString(),
    headers,
    config: {},
  };
}

export function errorResponse<T>(
  data: T,
  status = 400,
  headers: AxiosResponseHeaders = {},
): any {
  const error: any = new Error('error');
  error.response = response(data, status, headers);

  throw error;
}

export function errorResponseMap(map: {
  [key: string]: { status: number; data?: any; headers?: AxiosResponseHeaders };
}): (key: string) => void {
  return (key: string) => {
    if (!map[key]) return;

    const error: any = new Error('error');
    error.response = response(
      map[key].data ?? { errors: [`error_${map[key].status}`] },
      map[key].status,
      map[key].headers,
    );

    throw error;
  };
}
