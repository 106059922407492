import { MockFn } from '@/test/mocks/types';
import { GetTop200Response } from '@medpeer-dev/yakubato-lp-api';

export const createTopMock: MockFn<GetTop200Response> = (overwrite) => {
  return {
    medicalInstitutions: createMedicalInstitutionMocks(),
    ...overwrite,
  };
};

export const createMedicalInstitutionMocks = (
  count = 10,
): GetTop200Response['medicalInstitutions'] => {
  return Array(count)
    .fill(0)
    .map((_, index) =>
      createMedicalInstitutionMock({
        slug: `test-slug-${index}`,
        name: `やくばと医療機関 ${index}`,
        lpLogoImageUrl:
          index % 2
            ? `https://dummyimage.com/170x30/000000/ffffff&text=logo${index}`
            : `https://dummyimage.com/170x300/000000/ffffff&text=logo${index}`,
      }),
    );
};

const createMedicalInstitutionMock: MockFn<
  GetTop200Response['medicalInstitutions'][number]
> = (overwrite) => {
  return {
    slug: 'test-slug',
    name: 'やくばと医療機関',
    lpLogoImageUrl: 'https://dummyimage.com/600x400/000000/ffffff&text=logo',
    ...overwrite,
  };
};
