import {
  TopApiFactory,
  MedicalInstitutionsApiFactory,
} from '@medpeer-dev/yakubato-lp-api';
import { config } from '../constants/app';
import axios from 'axios';

export function createApi() {
  const args: any[] = [{}, config.apiUrl, axios];

  return {
    topApi: TopApiFactory(...args),
    medicalInstitutionsApi: MedicalInstitutionsApiFactory(...args),
  };
}

export type Api = ReturnType<typeof createApi>;
