import { response } from './response';
import { sleep } from './sleep';
import { createTopMock } from '../top';
import { MockApi } from './types';
import { TopApi } from '@medpeer-dev/yakubato-lp-api';

export default {
  async getTop() {
    await sleep();
    return new Promise((resolve) => {
      return resolve(response(createTopMock()));
    });
  },
} as MockApi<TopApi>;
