import { createApp } from 'vue';
import { Api, createApi } from './api';
import { createMockApi } from '../../test/mocks/server';
import { createPinia } from 'pinia';

const createCustomApp: typeof createApp = (options) => {
  const app = createApp(options);

  // install pinia
  const pinia = createPinia();
  app.use(pinia);
  pinia.use(() => ({
    $api:
      process.env.MOCK_SERVER === '1'
        ? (createMockApi() as Api) // モック API は型定義を上書き
        : createApi(),
  }));

  return app;
};

export default createCustomApp;
