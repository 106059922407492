import { response } from './response';
import { sleep } from './sleep';
import { createMedicalInstitutionsMock } from '../medicalInstitutions';
import { MockApi } from './types';
import { MedicalInstitutionsApi } from '@medpeer-dev/yakubato-lp-api';

export default {
  async getMedicalInstitutions() {
    await sleep();
    return new Promise((resolve) => {
      return resolve(response(createMedicalInstitutionsMock()));
    });
  },
} as MockApi<MedicalInstitutionsApi>;
