import TopApiMock from './api/TopApiMock';
import MedicalInstitutionsApiMock from './api/MedicalInstitutionsApiMock';
import { Api } from '../../src/utils/api';

type ApiKey = keyof Api;

export function createMockApi(): Record<ApiKey, unknown> {
  return {
    topApi: TopApiMock,
    medicalInstitutionsApi: MedicalInstitutionsApiMock,
  };
}
