type AppConfig = {
  env: 'production' | 'staging' | 'test' | 'development';
  host: string;
  apiUrl: string;
};

export const config = ((): AppConfig => {
  const env = process.env.APP_ENV ?? 'development';

  switch (env) {
    case 'production': {
      return {
        env: 'production',
        host: 'https://yakubato.jp',
        apiUrl: 'https://patient-api.yakubato.jp/lp',
      };
    }
    case 'staging': {
      return {
        env: 'staging',
        host: 'https://stg.yakubato.jp',
        apiUrl: 'https://stg-patient-api.yakubato.jp/lp',
      };
    }
    case 'test': {
      return {
        env: 'test',
        host: '',
        apiUrl: '',
      };
    }
    default: {
      return {
        env: 'development',
        host: '',
        apiUrl: 'https://patient-api.yakubato.docker/lp',
      };
    }
  }
})();
