import { MockFn } from '@/test/mocks/types';
import { GetMedicalInstitutions200Response } from '@medpeer-dev/yakubato-lp-api';

export const createMedicalInstitutionsMock: MockFn<
  GetMedicalInstitutions200Response
> = (overwrite) => {
  return {
    lastUpdatedAt: '2023-09-22',
    medicalInstitutions: Array(20)
      .fill(0)
      .map((_, index) =>
        createMedicalInstitutionMock({
          slug: `test-slug-${index}`,
          name: `やくばと医療機関 ${index}`,
          enableMedicalAppointment: index % 2 === 0,
          enablePharmacyReservation: index % 2 !== 0 || index % 3 !== 0,
          zipCode1: '100',
          zipCode2: '0001',
          prefecture: ['東京都', '千葉県', '神奈川県'][index % 3],
          address: `ダミー東京都千代田区千代田1-${index}`,
        }),
      ),
    ...overwrite,
  };
};

const createMedicalInstitutionMock: MockFn<
  GetMedicalInstitutions200Response['medicalInstitutions'][number]
> = (overwrite) => {
  return {
    slug: 'test-slug',
    name: 'やくばと医療機関',
    enableMedicalAppointment: true,
    enablePharmacyReservation: true,
    zipCode1: '100',
    zipCode2: '0001',
    prefecture: '東京都',
    address: '東京都千代田区千代田1-1',
    ...overwrite,
  };
};
